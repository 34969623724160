import { useState } from 'react';
import Image from 'next/image'
import styles from "./OurBlogs.module.scss"
import Slider from "react-slick";
import Link from 'next/link';
import { blogsData } from '../../../data/BlogsData';
import { AncorSecoundaryButton } from '../Buttons/Buttons';

export default function OurBlogs() {
    const [ourBlogs, SetOurBlogs] = useState(blogsData);

    const settings = {
        dots: false,
        infinite: false,
        arrows: true,
        speed: 500,
        autoplay: false,
        autoplaySpeed: 3000,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    dots: true,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 2,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    return (
        <section className={`${styles.our_blogs} common_padding`}>
            <div className='container'>
                <div className='common_title text-center'>
                    <h2 id='revealUp'>Our Blogs</h2>
                    {/* <Image src="/icons/line.svg" width={120} height={2} alt='Line Icon'></Image> */}
                </div>
                <div className={styles.explore_btn}>
                    <Link href='/blogs'>Read All Blogs <svg xmlns="http://www.w3.org/2000/svg" width="10" height="9" viewBox="0 0 10 9" fill="none">
                        <path d="M1 0.5L5 4.5L1 8.5" stroke="#222222" strokeWidth="1.2" />
                        <path d="M5 0.5L9 4.5L5 8.5" stroke="#222222" strokeWidth="1.2" />
                    </svg></Link>
                </div>
                <div className={styles.activities_slider}>
                    <Slider {...settings} className="activity_slider">
                        {ourBlogs.map((blog) => (
                            <div className={`${styles.blog_child} common_title`} key={blog.title}>
                                <div className={styles.image_wrapper}>
                                    <Image src={blog.bgImage} className="wrapimage" alt={blog.slug} width={412} height={328}></Image>
                                </div>
                                <h3>{blog.title}</h3>
                                <p >{blog.description}</p>
                                <AncorSecoundaryButton
                                    redirectPage={`/blogs/${blog.slug}`}
                                    className={'margin_0_auto'}
                                    buttonText={'View Blog'}
                                />
                                {/* <Link className="secoundary_btn" href={`/blogs/${blog.slug}`}>View Blog</Link> */}
                            </div>
                        ))}
                    </Slider>
                </div>
                {/* <div className={styles.explore_btn}>
                    <Link href='/blogs' className='common_btn'>Read All Blogs</Link>
                </div> */}
                <div className={styles.explore_btn_mobile}>
                    <Link href='/blogs'>Read All Blogs <svg xmlns="http://www.w3.org/2000/svg" width="10" height="9" viewBox="0 0 10 9" fill="none">
                        <path d="M1 0.5L5 4.5L1 8.5" stroke="#222222" strokeWidth="1.2" />
                        <path d="M5 0.5L9 4.5L5 8.5" stroke="#222222" strokeWidth="1.2" />
                    </svg></Link>
                </div>
            </div>
        </section>
    )
}
